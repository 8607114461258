import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  Button,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Header from "../../common/components/Header";
import LoadingOverlay from "../LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import {
  analysisSelector,
  fetchHeaders,
  clearHeaders,
  fetchHeaderMatchingSuggestion,
  clearHeaderSuggestion,
  setHeaderMatching,
  fetchAnalyses,
  addAnalysisFile,
  clearAnalysisFiles,
  clearAnalyses,
  clearError,
} from "./analysisSlice";
import { useHistory, useRouteMatch } from "react-router-dom";
import { HeaderMatching, Header as CsvHeader } from "./type";
import {
  confirmHeaderMatchingApi,
  matchLipidDataApi,
  parseFileApi,
  processDataApi,
} from "../../common/fetchUtils";
import { ANALYSES } from "../../Router";
import { deflate } from "zlib";

const useStyles = makeStyles({
  container: {
    marginTop: "5rem",
  },
  rowEven: {
    backgroundColor: "#f2f2f2",
  },
  rowOdd: {
    backgroundColor: "#ffffff",
  },
  evenWidth: {
    width: "50%",
  },
  confirmButton: {
    float: "right",
    marginBottom: "2rem",
  },
  backButton: {
    float: "right",
    marginRight: "1rem",
    marginBottom: "2rem",
  },
  selectInputRoot: {
    "&:after": {
      borderBottom: "none !important",
    },
    "&:before": {
      borderBottom: "none !important",
    },
  },
});

type AvailableHeadersSelectProps = {
  selected: CsvHeader | null;
  handleChange: any;
};

const AvailableHeadersSelect: React.FC<AvailableHeadersSelectProps> = (
  props: AvailableHeadersSelectProps
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { headers } = useSelector(analysisSelector);

  return (
    <FormControl fullWidth>
      <Select
        fullWidth
        value={props.selected?.value ?? -1}
        onChange={props.handleChange}
        className={classes.selectInputRoot}
      >
        <MenuItem value="-1">
          <Typography variant="body2">
            <i>No match</i>
          </Typography>
        </MenuItem>
        {headers?.map((h: CsvHeader, index: Number) => (
          <MenuItem key={h.value} value={h.value}>
            <Typography variant="body1">{h.displayName}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type HeaderMappingSuggestionsProps = {
  suggestions: HeaderMatching[] | null;
  handleChange: (e: any, index: number) => void;
  headers: CsvHeader[] | null;
};

const HeaderMappingSuggestions: React.FC<HeaderMappingSuggestionsProps> = (
  props: HeaderMappingSuggestionsProps
) => {
  const classes = useStyles();
  const { suggestions: suggestedMappings, handleChange } = props;
  return (
    <List>
      {suggestedMappings
        ?.filter((match: HeaderMatching) => match.column)
        .map((match: HeaderMatching) => {
          const title = match.column;
          const selected =
            props.headers?.find(
              (h: CsvHeader) =>
                h.id === suggestedMappings[match.columnIndex].headerId
            ) ?? null;
          return (
            <ListItem
              key={match.columnIndex}
              dense
              className={
                match.columnIndex % 2 === 0 ? classes.rowEven : classes.rowOdd
              }
            >
              <ListItemText className={classes.evenWidth}>
                <Typography variant="subtitle2">{title}</Typography>
              </ListItemText>
              <ListItemText className={classes.evenWidth}>
                <AvailableHeadersSelect
                  selected={selected}
                  handleChange={(e: any) => handleChange(e, match.columnIndex)}
                />
              </ListItemText>
            </ListItem>
          );
        })}
    </List>
  );
};

const ConfirmHeaders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string | null>(null);
  const [confirmError, setConfirmError] = useState(false);
  const [confirmErrorText, setConfirmErrorText] = useState<string | null>(null);
  const {
    headerMatching,
    headers,
    analyses,
    error,
    errorText,
    loading: analysisLoading,
    loadingText: analysisLoadingText,
  } = useSelector(analysisSelector);

  const getAnalysisFileId = () => {
    const params: any = match.params;
    const analysisFileId = Number.parseInt(params?.id ?? "-1");
    if (analysisFileId === -1) {
      throw Error("Invalid parameter passed.");
    }
    return analysisFileId;
  };

  useEffect(() => {
    const cleanUp = () => {
      dispatch(clearHeaderSuggestion());
      dispatch(clearError());
    };
    const analysisFileId = getAnalysisFileId();
    dispatch(fetchHeaderMatchingSuggestion(analysisFileId));
    return cleanUp;
  }, []);

  useEffect(() => {
    dispatch(fetchHeaders());
    return () => {
      clearHeaders();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAnalyses(getAnalysisFileId()));
    return () => {
      clearAnalyses();
    };
  }, []);

  const handleChange = (e: any, index: number) => {
    const header = headers?.find((h) => h.value === e.target.value) ?? null;
    dispatch(setHeaderMatching({ columnIndex: index, header }));
  };

  const onCloseAlert = () => {
    setConfirmError(false);
    dispatch(clearError());
  };

  const onSaveClicked = async () => {
    if (headerMatching === null) {
      return;
    }
    setLoading(true);
    const analysisFileId = getAnalysisFileId();

    await confirmHeaders(headerMatching, analysisFileId);

    if (confirmError) {
      return;
    }

    // await parseFile(analysisFileId);

    // if (confirmError) {
    //   return;
    // }

    // await matchLipidData(analysisFileId);

    await processFile(analysisFileId);
    setLoading(false);
  };

  const confirmHeaders = async (
    headerMatching: HeaderMatching[],
    analysisFileId: number
  ) => {
    try {
      setLoadingText("Verifying headers...");
      await confirmHeaderMatchingApi(analysisFileId, headerMatching);
    } catch (e) {
      setConfirmError(true);
      setConfirmErrorText(
        "An error occurred while verifying the headers. Please try again!"
      );
      setLoading(false);
    }
  };

  const processFile = async (analysisFileId: number) => {
    try {
      setLoadingText("Processing data..");
      const analysisFile = await processDataApi(analysisFileId);
      dispatch(addAnalysisFile(analysisFile));
      history.push(ANALYSES);
    } catch (error) {
      setConfirmError(true);
      setConfirmErrorText(
        "An error occurred while processing the file. Please try again!"
      );
      setLoading(false);
    }
  };

  const parseFile = async (analysisFileId: number) => {
    try {
      setLoadingText(
        "Parsing file... This process can take up to 5 minutes, depending on the size of the file. Please stay on the page until finished."
      );
      await parseFileApi(analysisFileId);
    } catch (error) {
      setConfirmError(true);
      setConfirmErrorText(
        "An error occurred while parsing the file. Please try again!"
      );
      setLoading(false);
    }
  };

  const matchLipidData = async (analysisFileId: number) => {
    try {
      setLoadingText("Matching lipid data...");
      await matchLipidDataApi(analysisFileId);
      if (analyses) {
        history.push(`${ANALYSES}/${analyses[0].id}`);
      } else {
        history.push(ANALYSES);
      }
    } catch (error) {
      setConfirmError(true);
      setConfirmErrorText(
        "An error occurred while matching the data. Please try again!"
      );
      setLoading(false);
    }
  };

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <Header title={"Upload analysis"} />
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} md={8} className={classes.container}>
            <Typography variant="h4">Verify headers</Typography>
            <HeaderMappingSuggestions
              suggestions={headerMatching}
              headers={headers}
              handleChange={handleChange}
            />

            <Button
              disabled={!headerMatching}
              size="large"
              className={classes.confirmButton}
              variant="contained"
              color="secondary"
              onClick={onSaveClicked}
            >
              Save
            </Button>
            <Button
              size="large"
              className={classes.backButton}
              variant="contained"
              onClick={onBackClick}
            >
              Back
            </Button>
            <Snackbar
              color="error"
              open={confirmError || error}
              autoHideDuration={6000}
              onClose={onCloseAlert}
            >
              <MuiAlert variant="filled" severity="error">
                {errorText || confirmErrorText}
              </MuiAlert>
            </Snackbar>
          </Grid>
        </Grid>
      </Container>
      <LoadingOverlay
        open={loading || analysisLoading}
        loadingText={loadingText || analysisLoadingText}
        color="white"
      />
    </React.Fragment>
  );
};

export default ConfirmHeaders;
