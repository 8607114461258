import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  CircularProgress,
  Container,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { ANALYSES } from "../../Router";
import { loginApi } from "../../common/fetchUtils";
import { ReactComponent as Logo } from "../../logo.svg";
import "./Login.css";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
  },
  logo: {
    width: "90px",
    paddingTop: "1rem",
  },
});

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loginError, setLoginError] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState("");

  const initialValues = {
    name: "",
    password: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = async (values: any) => {
    loginApi(values.name, values.password)
      .then((response) => {
        history.push(ANALYSES);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setLoginErrorText("Invalid username or password.");
        } else {
          setLoginErrorText("Oops.. Something went wrong!");
          console.log(error.response);
        }
        setLoginError(true);
      });
  };

  return (
    <Container className={"login-background"} maxWidth={false}>
      <Grid container alignItems={"center"} justify={"center"}>
        <Grid item md={3} sm={6} xs={12}>
          <Card className={classes.root}>
            <Logo className={classes.logo} />
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  dirty,
                  isSubmitting,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <CardContent>
                      <TextField
                        name="name"
                        label={"Username"}
                        error={Boolean(errors.name) && Boolean(touched.name)}
                        helperText={
                          Boolean(errors.name) && touched.name && errors.name
                        }
                        fullWidth
                        required
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        margin={"normal"}
                        name="password"
                        type="password"
                        value={values.password}
                        label={"Password"}
                        error={
                          Boolean(errors.password) && Boolean(touched.password)
                        }
                        helperText={
                          Boolean(errors.password) &&
                          touched.password &&
                          errors.password
                        }
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </CardContent>
                    <CardActions>
                      <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disabled={
                          !dirty ||
                          isSubmitting ||
                          Object.keys(errors).length > 0
                        }
                      >
                        {isSubmitting ? (
                          <CircularProgress size={24} color="secondary" />
                        ) : (
                          "Log in"
                        )}
                      </Button>
                    </CardActions>
                  </form>
                );
              }}
            </Formik>
          </Card>
          <Snackbar
            color="error"
            open={loginError}
            autoHideDuration={6000}
            onClose={() => setLoginError(false)}
          >
            <MuiAlert variant="filled" severity="error">
              {loginErrorText}
            </MuiAlert>
          </Snackbar>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
