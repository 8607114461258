import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Fab,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import { useHistory } from "react-router-dom";
import moment from "moment";

// Own imports
import Header from "../../common/components/Header";
import "./Analysis.css";
import { UPLOAD_ANALYSIS, ANALYSES } from "../../Router";
import { AnalysisFile } from "./type";
import {
  clearAnalysisFiles,
  deleteAnalysisFile,
  analysisSelector,
  fetchAnalysisFiles,
  setCurrentAnalysis,
} from "./analysisSlice";
import StyledTableRow from "../../common/components/StyledTableRow";
import StyledTableCell from "../../common/components/StyledTableCell";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "absolute",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    table: {
      minWidth: 700,
    },
    container: {
      paddingTop: "64px",
      alignItems: "start",
    },
    menuButton: {
      padding: "2px",
    },
    disabled: {
      color: "grey",
    },
  })
);

const NoAnalysesPlaceholder = () => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={6}>
        <div className={"center-text"}>
          <AssignmentIcon style={{ fontSize: 160 }} />
        </div>
        <div className={"center-text"}>
          <Typography variant="h5">
            You haven't added any analysis yet. Start by uploading your first
            analysis file with the Plus button in the right bottom corner.
          </Typography>
        </div>
      </Grid>
    </React.Fragment>
  );
};

type AnalysisFilesTablePros = {
  items: AnalysisFile[] | null;
};

const AnalysisFilesTable: React.FC<AnalysisFilesTablePros> = (
  props: AnalysisFilesTablePros
) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [analysisMenu, setAnalysisMenu] = useState<null | AnalysisFile>(null);

  useEffect(() => {
    dispatch(setCurrentAnalysis(null));
  }, []);

  const analysisFileClicked = (analysisFile: AnalysisFile) => {
    if (!analysisFile.analysis) {
      return;
    }

    if (isDisabled(analysisFile)) {
      return;
    }
    dispatch(setCurrentAnalysis(analysisFile.analysis));
    if (analysisFile.hasMatchedHeaders) {
      history.push(`${ANALYSES}/${analysisFile.analysis.id}`);
    } else {
      history.push(`/confirm-headers/${analysisFile.id}`);
    }
  };

  const editHeaders = () => {
    if (!analysisMenu) {
      return;
    }

    dispatch(setCurrentAnalysis(analysisMenu.analysis));
    handleMenuClose();
    history.push(`/confirm-headers/${analysisMenu.id}`);
  };

  const onDeleteAnalysisFile = () => {
    if (!analysisMenu) {
      return;
    }
    dispatch(deleteAnalysisFile(analysisMenu.id));
    handleMenuClose();
  };

  const menuClicked = (
    event: React.MouseEvent<HTMLButtonElement>,
    analysisFile: AnalysisFile
  ) => {
    event.stopPropagation();
    setAnalysisMenu(analysisFile);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnalysisMenu(null);
    setMenuAnchorEl(null);
  };

  const isDisabled = (file: AnalysisFile) => {
    const allowedStatuses = ["COMPLETED", "FAILED", "INITIATED"];
    return allowedStatuses.findIndex((s) => s === file.parsingStatus) === -1;
  };

  const isEditHeaderDisabled = (file: AnalysisFile) => {
    const disabledMatchingStatuses = ["PENDING"];
    return (
      isDisabled(file) ||
      disabledMatchingStatuses.findIndex((s) => s === file.matchingStatus) > -1
    );
  };

  return (
    <Fragment>
      <Menu
        id="edit-analysis-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={editHeaders}
          disabled={
            analysisMenu !== null ? isEditHeaderDisabled(analysisMenu) : false
          }
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit headers" />
        </MenuItem>
        <MenuItem onClick={onDeleteAnalysisFile}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography variant="h6">File name</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="h6">Parsing</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="h6">Matching</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="h6">Analysis type</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="h6">Date</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="h6">Actions</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items?.map((row: AnalysisFile) => (
              <StyledTableRow
                hover={!isDisabled(row)}
                key={row.id}
                className={classes.disabled}
                onClick={() => analysisFileClicked(row)}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  className={isDisabled(row) ? classes.disabled : ""}
                >
                  <Typography variant="body1">{row.filename}</Typography>
                </StyledTableCell>
                <StyledTableCell
                  className={isDisabled(row) ? classes.disabled : ""}
                >
                  <Typography variant="body1">{row.parsingStatus}</Typography>
                </StyledTableCell>
                <StyledTableCell
                  className={isDisabled(row) ? classes.disabled : ""}
                >
                  <Typography variant="body1">{row.matchingStatus}</Typography>
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  className={isDisabled(row) ? classes.disabled : ""}
                >
                  <Typography variant="body1">
                    {row.analysis?.analysisType}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  className={isDisabled(row) ? classes.disabled : ""}
                >
                  <Typography variant="body1">
                    {moment(row.created).format("DD.MM.YYYY")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  className={isDisabled(row) ? classes.disabled : ""}
                >
                  <IconButton
                    className={classes.menuButton}
                    onClick={(e) => menuClicked(e, row)}
                  >
                    <MenuIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const Analyses = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { analysisFiles } = useSelector(analysisSelector);

  useEffect(() => {
    dispatch(fetchAnalysisFiles());
  }, []);

  const showUploadForm = () => {
    history.push(UPLOAD_ANALYSIS);
  };

  return (
    <React.Fragment>
      <Header title="My analyses" />
      <Container className={classes.container}>
        <Grid
          container
          alignItems={analysisFiles?.length === 0 ? "center" : "baseline"}
          justify="center"
        >
          {analysisFiles?.length === 0 ? (
            <NoAnalysesPlaceholder />
          ) : (
            <AnalysisFilesTable items={analysisFiles}></AnalysisFilesTable>
          )}
          <Fab
            color="secondary"
            className={classes.fab}
            onClick={showUploadForm}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Analyses;
