import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Login from "./features/login/Login";
import Analyses from "./features/analysis/Analyses";
import UploadAnalysis from "./features/analysis/UploadAnalysis";
import ConfirmHeaders from "./features/analysis/ConfirmHeaders";
import Analysis from "./features/analysis/Analysis";

export const CONFIRM_HEADERS = "/confirm-headers";
export const UPLOAD_ANALYSIS = "/upload-analysis";
export const ANALYSES = "/analyses";
export const LOGIN = "/login";

const DefaultRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path={LOGIN}>
          <Login />
        </Route>
        <Route path={ANALYSES} exact>
          <Analyses />
        </Route>
        <Route path={`${ANALYSES}/:id`} component={Analysis} exact></Route>
        <Route path={UPLOAD_ANALYSIS}>
          <UploadAnalysis />
        </Route>
        <Route
          path={`${CONFIRM_HEADERS}/:id`}
          component={ConfirmHeaders}
        ></Route>
        <Redirect from="/" to={LOGIN}></Redirect>
      </Switch>
    </Router>
  );
};

export default DefaultRouter;
