import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

import Navbar from "../../features/Navbar";

type Props = {
  title?: string;
  children?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const Header: React.FC<Props> = (props: Props) => {
  const [navbarOpen, setNavbarOpen] = useState<boolean>(false);
  const classes = useStyles();

  const toggleMenu = (event: any) => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <React.Fragment>
      <AppBar position={"fixed"}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.menuButton}
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant={"h5"} className={classes.title}>
            {props.title}
          </Typography>
          {props.children}
        </Toolbar>
      </AppBar>
      <Navbar open={navbarOpen} onClose={() => setNavbarOpen(false)} />
    </React.Fragment>
  );
};

export default Header;
