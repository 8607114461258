import { withStyles, Theme, createStyles, TableRow } from "@material-ui/core";

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    hover: {
      "&:hover": {
        backgroundColor: "#ededed !important",
      },
    },
  })
)(TableRow);

export default StyledTableRow;
