import React from "react";
import { useHistory } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { logoutApi } from "../common/fetchUtils";
import DrawerImg from "../drawer.jpg";

type NavbarProps = {
  open: boolean;
  onClose: () => void;
};

const useStyles = makeStyles({
  root: {
    position: "absolute",
    bottom: "0",
    width: "100%",
  },
});

const Navbar: React.FC<NavbarProps> = (props: NavbarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const routeTo = (route: string) => {
    if (history.location.pathname !== route) {
      history.push(route);
    }
  };
  const logout = () => {
    logoutApi().finally(() => routeTo("/login"));
  };
  return (
    <Drawer {...props}>
      <img src={DrawerImg} width="250" />
      <List>
        <ListItem button>
          <ListItemText onClick={() => routeTo("/analyses")}>
            <Typography variant="subtitle1">My analayses</Typography>
          </ListItemText>
        </ListItem>
      </List>
      <List className={classes.root}>
        <ListItem button>
          <ListItemText onClick={logout}>
            <Typography variant="subtitle1">Log out</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Navbar;
