import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";
import {
  AnalysisType,
  AnalysisState,
  AnalysisFile,
  Header,
  HeaderMatching,
  Measurement,
  Analysis,
} from "./type";
import {
  getAnalysisTypesApi,
  getAnalysisFilesApi,
  getHeadersApi,
  getHeaderMatchingSuggestionApi,
  getMeasurementsApi,
  getAnalysesApi,
  getAnalysisApi,
  deleteAnalysisFileApi,
  confirmHeaderMatchingApi,
  parseFileApi,
} from "../../common/fetchUtils";

export const fetchAnalysisTypes = createAsyncThunk(
  "analysis/fetchAnalysisTypes",
  async (arg: undefined, { rejectWithValue }) => {
    try {
      const analysisTypes = await getAnalysisTypesApi();
      return analysisTypes;
    } catch (error) {
      return rejectWithValue("Failed to fetch analysis types");
    }
  }
);

export const fetchAnalysisFiles = createAsyncThunk(
  "analysis/fetchAnalysisFiles",
  async (arg: undefined, { rejectWithValue }) => {
    try {
      const files = await getAnalysisFilesApi();
      return files;
    } catch (error) {
      return rejectWithValue("Failed to fetch analysis files");
    }
  }
);

export const deleteAnalysisFile = createAsyncThunk(
  "analysis/deleteAnalysisFile",
  async (analysisFileId: number, { rejectWithValue }) => {
    try {
      const response = await deleteAnalysisFileApi(analysisFileId);
      return analysisFileId;
    } catch (error) {
      return rejectWithValue("Failed to delete analysis file.");
    }
  }
);

export const fetchHeaders = createAsyncThunk(
  "analysis/fetchHeaders",
  async (arg: undefined, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      if (state?.analysis?.headers?.length > 0) {
        return state?.analysis?.headers;
      }
      const headers = await getHeadersApi();
      return headers;
    } catch (error) {
      return rejectWithValue("Failed to fetch headers");
    }
  }
);

export const fetchHeaderMatchingSuggestion = createAsyncThunk(
  "analysis/getHeaderMatchingSuggestion",
  async (analysisFileId: number, { rejectWithValue }) => {
    try {
      const suggestion = await getHeaderMatchingSuggestionApi(analysisFileId);
      return suggestion;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmHeaderMatching = createAsyncThunk(
  "analysis/configmHeaderMatching",
  async (
    arg: { analysisFileId: number; headerMatching: any },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await confirmHeaderMatchingApi(arg.analysisFileId, arg.headerMatching);
      dispatch(parseAnalysisFile(arg.analysisFileId));
      return true;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const parseAnalysisFile = createAsyncThunk(
  "analysis/parseAnalysisFile",
  async (analysisFileId: number, { rejectWithValue }) => {
    try {
      await parseFileApi(analysisFileId);
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMeasurements = createAsyncThunk(
  "analysis/fetchMeasurements",
  async (
    args: { analysisId: number; pageNumber?: number },
    { rejectWithValue }
  ) => {
    try {
      const paginatedResult = await getMeasurementsApi(
        args.analysisId,
        args.pageNumber
      );
      return {
        analysisId: args.analysisId,
        measurements: paginatedResult.results,
        hasMore: paginatedResult.next !== null,
      };
    } catch (error) {
      return rejectWithValue("Failed to fetch measurements");
    }
  }
);

export const fetchAnalyses = createAsyncThunk(
  "analysis/fetchAnalyses",
  async (analysisFileId: number | undefined, { rejectWithValue }) => {
    try {
      const analyses = await getAnalysesApi(analysisFileId);
      return analyses;
    } catch (error) {
      return rejectWithValue("failed to fetch analyses");
    }
  }
);

export const fetchCurrentAnalysis = createAsyncThunk(
  "analysis/fetchAnalysis",
  async (analysisId: number, { getState, rejectWithValue }) => {
    try {
      const state = getState() as any;
      const analysisState: AnalysisState = state.analysis;
      if (analysisState.currentAnalysis) {
        return analysisState.currentAnalysis;
      }
      const analysis = await getAnalysisApi(analysisId);
      return analysis;
    } catch (error) {
      return rejectWithValue("Failed to fetch current analysis");
    }
  }
);

export const initialState: AnalysisState = {
  analysisTypes: null,
  analysisFiles: null,
  headers: null,
  loading: false,
  loadingText: "",
  error: false,
  errorText: null,
  headerMatching: null,
  analysisMeasurements: {},
  analyses: null,
  currentAnalysis: null,
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    clearAnalyses: (state) => {
      state.analyses = null;
    },
    clearAnalysisTypes: (state) => {
      state.analysisTypes = null;
    },
    addAnalysisFile: (state, action: PayloadAction<AnalysisFile>) => {
      if (state.analysisFiles === null) {
        state.analysisFiles = [action.payload];
      } else {
        const index = state.analysisFiles.findIndex(
          (a) => a.id === action.payload.id
        );
        if (index > -1) {
          state.analysisFiles[index] = action.payload;
        } else {
          state.analysisFiles.push(action.payload);
        }
      }
    },
    clearAnalysisFiles: (state) => {
      state.analysisFiles = null;
    },
    clearHeaders: (state) => {
      state.headers = null;
    },
    clearHeaderSuggestion: (state) => {
      state.headerMatching = null;
    },
    setCurrentAnalysis: (
      state: AnalysisState,
      action: PayloadAction<Analysis | null>
    ) => {
      state.currentAnalysis = action.payload;
    },
    setHeaderMatching: (
      state: AnalysisState,
      action: PayloadAction<{ columnIndex: number; header: Header | null }>
    ) => {
      const match = state.headerMatching?.find((m) => {
        return m.columnIndex === action.payload.columnIndex;
      });
      if (match) {
        match.headerId = action.payload.header?.id ?? null;
      }
    },
    clearAnalysisMeasurements: (
      state: AnalysisState,
      action: PayloadAction<number>
    ) => {
      if (state.analysisMeasurements) {
        delete state.analysisMeasurements[action.payload];
      }
    },
    clearError: (state) => {
      state.error = false;
      state.errorText = null;
    },
  },
  extraReducers: {
    [fetchAnalysisTypes.fulfilled.type]: (
      state,
      action: PayloadAction<AnalysisType[]>
    ) => {
      state.analysisTypes = action.payload;
    },
    [fetchAnalysisFiles.fulfilled.type]: (
      state,
      action: PayloadAction<AnalysisFile[]>
    ) => {
      state.analysisFiles = action.payload;
    },
    [deleteAnalysisFile.fulfilled.type]: (
      state: AnalysisState,
      action: PayloadAction<number>
    ) => {
      state.analysisFiles =
        state.analysisFiles?.filter((f) => f.id !== action.payload) ?? null;
    },
    [fetchHeaders.fulfilled.type]: (state, action: PayloadAction<Header[]>) => {
      state.headers = action.payload;
    },
    [fetchHeaderMatchingSuggestion.pending.type]: (state) => {
      state.headerMatching = null;
      state.loading = true;
      state.loadingText = "Fetching header suggestion...";
    },
    [fetchHeaderMatchingSuggestion.fulfilled.type]: (
      state,
      action: PayloadAction<HeaderMatching[]>
    ) => {
      state.headerMatching = action.payload;
      state.loading = false;
      state.loadingText = "";
    },
    [fetchHeaderMatchingSuggestion.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.error = true;
      state.errorText = `Failed to get a suggestion: ${action.payload.response?.data}`;
      state.loading = false;
      state.loadingText = "";
      console.log(action.payload.response);
    },
    [confirmHeaderMatching.pending.type]: (state) => {
      state.loading = true;
      state.loadingText = "Verifying headers...";
      state.error = false;
      state.errorText = null;
    },
    [confirmHeaderMatching.fulfilled.type]: (state) => {
      state.loading = false;
      state.loadingText = "";
      state.error = false;
      state.errorText = null;
    },
    [confirmHeaderMatching.rejected.type]: (state) => {
      state.loading = false;
      state.loadingText = "";
      state.error = true;
      state.errorText =
        "An error occurred while verifying the headers. Please try again!";
    },
    [parseAnalysisFile.pending.type]: (state) => {
      state.loading = true;
      state.loadingText = "Parsing file...";
      state.error = false;
      state.errorText = null;
    },
    [parseAnalysisFile.fulfilled.type]: (state) => {
      state.loading = false;
      state.loadingText = "";
      state.error = false;
      state.errorText = null;
    },
    [parseAnalysisFile.rejected.type]: (state) => {
      state.loading = false;
      state.loadingText = "";
      state.error = true;
      state.errorText =
        "An error occurred while parsing the file. Please try again!";
    },
    [fetchMeasurements.fulfilled.type]: (
      state: AnalysisState,
      action: PayloadAction<{
        analysisId: number;
        measurements: Measurement[];
        hasMore: boolean;
      }>
    ) => {
      if (state.analysisMeasurements[action.payload.analysisId]) {
        const allMeasurements =
          state.analysisMeasurements[action.payload.analysisId].items?.concat(
            action.payload.measurements
          ) ?? null;
        const hasMore = action.payload.hasMore;
        state.analysisMeasurements[action.payload.analysisId] = {
          items: allMeasurements,
          hasMore,
        };
      } else {
        state.analysisMeasurements[action.payload.analysisId] = {
          items: action.payload.measurements,
          hasMore: action.payload.hasMore,
        };
      }
    },
    [fetchAnalyses.fulfilled.type]: (
      state,
      action: PayloadAction<Analysis[]>
    ) => {
      state.analyses = action.payload;
    },
    [fetchCurrentAnalysis.fulfilled.type]: (
      state,
      action: PayloadAction<Analysis>
    ) => {
      state.currentAnalysis = action.payload;
    },
  },
});

export const analysisSelector = (state: RootState): AnalysisState =>
  state.analysis;

export const {
  clearAnalysisTypes,
  clearAnalysisFiles,
  addAnalysisFile,
  clearHeaders,
  clearHeaderSuggestion,
  setHeaderMatching,
  clearAnalysisMeasurements,
  clearAnalyses,
  setCurrentAnalysis,
  clearError,
} = analysisSlice.actions;

export default analysisSlice.reducer;
