import { combineReducers } from "@reduxjs/toolkit";
import analysisReducer from "../features/analysis/analysisSlice";

const rootReducer = combineReducers({
  analysis: analysisReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
