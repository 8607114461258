import React, { useEffect, useState } from "react";
import Header from "../../common/components/Header";
import {
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { CONFIRM_HEADERS } from "../../Router";
import {
  analysisSelector,
  fetchAnalysisTypes,
  clearAnalysisTypes,
} from "./analysisSlice";
import { AnalysisType } from "./type";
import { uploadAnalysisFileApi } from "../../common/fetchUtils";
import LoadingOverlay from "../LoadingOverlay";

const MAX_FILE_SIZE = 20000000;

const useStyles = makeStyles({
  submitButton: {
    float: "right",
  },
});

const initialValues: UploadAnalysisForm = {
  fileName: "",
  analysisType: "",
};

type UploadAnalysisForm = {
  fileName: string;
  analysisType: string;
  file?: any;
};

const validationSchema = Yup.object({
  fileName: Yup.string()
    .required("Select a file to proceed")
    .min(5, "File name too short"),
  analysisType: Yup.number()
    .required("Please select the type of analysis")
    .positive("Select a value from the list"),
});

type AnalysisSelectProps = {
  items: AnalysisType[] | null;
  name: string;
  onChange: any;
  value: any;
  error: boolean;
  onBlur: any;
};

const AnalysisTypeSelect: React.FC<AnalysisSelectProps> = (
  props: AnalysisSelectProps
) => {
  const { items, ...controlProps } = props;
  if (items === null) {
    return <p>Loading</p>;
  }
  return (
    <FormControl variant="outlined" fullWidth size="small" margin="normal">
      <InputLabel id="analysisTypeSelectLabel" error={props.error}>
        Define type of data
      </InputLabel>
      <Select
        labelId="analysisTypeSelectLabel"
        label="Define type of data"
        fullWidth
        placeholder="Choose"
        {...controlProps}
      >
        {items.map((type: AnalysisType, index: number) => (
          <MenuItem value={type.id} key={type.id}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const UploadAnalysis = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadError, setUploadError] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [uploadingText, setUploadingText] = useState("Uploading analysis..");
  const { analysisTypes } = useSelector(analysisSelector);

  useEffect(() => {
    dispatch(fetchAnalysisTypes());
    return () => {
      clearAnalysisTypes();
    };
  }, []);

  const onSubmit = async (values: UploadAnalysisForm) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", values.file);
    formData.append("analysis_type", values.analysisType);
    formData.append("file_name", values.fileName);

    uploadAnalysisFileApi(formData)
      .then((response) => {
        setUploading(false);
        history.replace(`${CONFIRM_HEADERS}/${response.id}`);
      })
      .catch((error) => {
        setUploadError(true);
        setUploading(false);
      });
  };

  return (
    <React.Fragment>
      <Header title="Upload analysis" />
      <Container>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  dirty,
                  initialValues,
                  isSubmitting,
                  setFieldValue,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Typography variant="h5">Upload analysis</Typography>
                    <DropzoneArea
                      acceptedFiles={[".csv", ".txt"]}
                      filesLimit={1}
                      maxFileSize={MAX_FILE_SIZE}
                      onChange={(e: any) => {
                        let newName = initialValues.fileName;
                        if (e && e.length > 0) {
                          newName = e[0].name;
                          touched.fileName = true;
                          setFieldValue("file", e[0]);
                        } else {
                          setFieldValue("file", null);
                        }
                        setFieldValue("fileName", newName);
                      }}
                    />
                    <TextField
                      name="fileName"
                      label="Filename"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      onChange={(e: any) => {
                        setFieldValue("fileName", e.target.value);
                      }}
                      error={
                        Boolean(errors.fileName) && Boolean(touched.fileName)
                      }
                      helperText={
                        Boolean(errors.fileName) &&
                        touched.fileName &&
                        errors.fileName
                      }
                      value={values.fileName}
                      size="small"
                    />
                    <AnalysisTypeSelect
                      items={analysisTypes}
                      name="analysisType"
                      error={
                        Boolean(errors.analysisType) &&
                        Boolean(touched.analysisType)
                      }
                      onBlur={handleBlur}
                      value={values.analysisType}
                      onChange={handleChange}
                    />
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      className={classes.submitButton}
                      disabled={
                        !dirty ||
                        isSubmitting ||
                        isUploading ||
                        Object.keys(errors).length > 0
                      }
                    >
                      Upload analysis
                    </Button>
                  </form>
                );
              }}
            </Formik>
            <Snackbar
              color="error"
              open={uploadError}
              autoHideDuration={6000}
              onClose={() => setUploadError(false)}
            >
              <MuiAlert variant="filled" severity="error">
                Oops.. Something went wrong. Please try again later!
              </MuiAlert>
            </Snackbar>
          </Grid>
        </Grid>
      </Container>
      <LoadingOverlay
        open={isUploading}
        loadingText={uploadingText}
        color="white"
      />
    </React.Fragment>
  );
};

export default UploadAnalysis;
