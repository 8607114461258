import axios, { AxiosProxyConfig, AxiosRequestConfig } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import {
  AnalysisType,
  AnalysisFile,
  Header,
  HeaderMatching,
  AnalysisFileUpload,
  Analysis,
  Measurement,
  PaginationResult,
} from "../features/analysis/type";
import { LOGIN, ANALYSES } from "../Router";

export const ANALYSIS_ENDPOINT = "analysis/";
const ANALYSIS_FILES_ENDPOINT = "analysis/files/";
const ANALYSIS_TYPES_ENDPOINT = "analysis/types/";
const HEADERS_ENDPOINT = "analysis/headers/";
const HEADER_MATCHING_ENDPOINT = "analysis/header-suggestion/";
const LOGIN_ENDPOINT = "login/";
const LOGOUT_ENDPOINT = "api-auth/logout/";

const getCookie = (name: string): string | null => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const getApiClient = () => {
  const config: AxiosRequestConfig = {
    baseURL: "/api/",
    headers: {},
  };

  const csrftoken = getCookie("csrftoken");
  if (csrftoken) {
    config.headers["X-CSRFToken"] = csrftoken;
  }

  const client = axios.create(config);
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 403) {
        window.location.replace(LOGIN);
      }
      return new Promise((resolve, reject) => reject(error));
    }
  );

  return client;
};

export const getAnalysisTypesApi = (): Promise<AnalysisType[]> => {
  const api = getApiClient();
  return api.get(ANALYSIS_TYPES_ENDPOINT).then((response) => response.data);
};

export const uploadAnalysisFileApi = (
  args: FormData
): Promise<AnalysisFileUpload> => {
  const api = getApiClient();
  return api
    .post(ANALYSIS_FILES_ENDPOINT, args)
    .then((response) => response.data);
};

export const getAnalysisFilesApi = (): Promise<AnalysisFile[]> => {
  let api = getApiClient();
  api = applyCaseMiddleware(api);
  return api.get(ANALYSIS_FILES_ENDPOINT).then((response) => response.data);
};

export const deleteAnalysisFileApi = (analysisFileId: number): Promise<any> => {
  const api = getApiClient();
  return api
    .delete(`${ANALYSIS_FILES_ENDPOINT}${analysisFileId}/`)
    .then((response) => response.data);
};

export const getHeadersApi = (): Promise<Header[]> => {
  const api = applyCaseMiddleware(getApiClient());
  return api.get(HEADERS_ENDPOINT).then((response) => response.data);
};

export const getHeaderMatchingSuggestionApi = (
  analysisFileId: number
): Promise<HeaderMatching[]> => {
  const api = applyCaseMiddleware(getApiClient());
  return api
    .get(`${HEADER_MATCHING_ENDPOINT}?id=${analysisFileId}`)
    .then((response) => response.data);
};

export const confirmHeaderMatchingApi = (
  analysisFileId: number,
  headerMatchings: HeaderMatching[]
) => {
  const api = getApiClient();
  const headers = {
    "Content-Type": "application/json",
  };

  const data = headerMatchings.map((h: any) => {
    const item: any = {};
    Object.keys(h).forEach((k) => (item[camelToUnderscore(k)] = h[k]));
    return item;
  });
  return api.post(
    `${HEADER_MATCHING_ENDPOINT}${analysisFileId}/`,
    JSON.stringify({ data }),
    { headers }
  );
};

function camelToUnderscore(key: any): string {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export const loginApi = (username: string, password: string) => {
  const credentials = new FormData();
  credentials.set("username", username);
  credentials.set("password", password);
  const api = getApiClient();
  return api.post(LOGIN_ENDPOINT, credentials);
};

export const logoutApi = () => {
  const api = getApiClient();
  return api.get(LOGOUT_ENDPOINT);
};

export const parseFileApi = (analysisFileId: number) => {
  const api = getApiClient();
  return api
    .post(`${ANALYSIS_FILES_ENDPOINT}${analysisFileId}/parse/`)
    .then((response) => response.data);
};

export const matchLipidDataApi = (analysisFileId: number) => {
  const api = getApiClient();
  return api
    .post(`${ANALYSIS_FILES_ENDPOINT}${analysisFileId}/match/`)
    .then((response) => response.data);
};

export const processDataApi = (
  analysisFileId: number
): Promise<AnalysisFile> => {
  const options = {
    ignoreHeaders: true,
  };
  const api = applyCaseMiddleware(getApiClient(), options);
  return api
    .post(`${ANALYSIS_FILES_ENDPOINT}${analysisFileId}/process/`)
    .then((response) => response.data);
};

export const getMeasurementsApi = (
  analysisId: number,
  pageNumber?: number
): Promise<PaginationResult<Measurement>> => {
  const api = applyCaseMiddleware(getApiClient());
  let uri = `${ANALYSIS_ENDPOINT}${analysisId}/measurements/`;
  if (pageNumber) {
    uri = uri + `?page=${pageNumber}`;
  }
  return api.get(uri).then((response) => response.data);
};

export const getAnalysesApi = (
  analysisFileId?: number
): Promise<Analysis[]> => {
  const api = applyCaseMiddleware(getApiClient());
  const url = analysisFileId
    ? `${ANALYSIS_ENDPOINT}?analysis_file_id=${analysisFileId}`
    : ANALYSIS_ENDPOINT;
  return api.get(url).then((response) => response.data);
};

export const getAnalysisApi = (analysisId: number): Promise<Analysis> => {
  const api = applyCaseMiddleware(getApiClient());
  return api
    .get(`${ANALYSIS_ENDPOINT}${analysisId}/`)
    .then((response) => response.data);
};

export const downloadMeasurementsAsCsvApi = (analysisId: number) => {
  const api = getApiClient();
  const url = `${ANALYSIS_ENDPOINT}${analysisId}/export/`;
  return api.get(url).then((response) => response);
};
