import React from "react";
import { CircularProgress, Backdrop, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  overlay: {
    zIndex: 10,
    flexDirection: "column",
    color: (props: LoadingOverlayProps) => props.color,
  },
});

type LoadingOverlayProps = {
  open: boolean;
  loadingText?: string;
  color?: string;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = (
  props: LoadingOverlayProps
) => {
  const classes = useStyles(props);
  const { open, loadingText } = props;
  return (
    <Backdrop open={open} className={classes.overlay} color={props.color}>
      <CircularProgress color={"inherit"}></CircularProgress>
      {loadingText && (
        <Typography variant="subtitle1">{loadingText}</Typography>
      )}
    </Backdrop>
  );
};

export default LoadingOverlay;
