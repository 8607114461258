import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Header from "../../common/components/Header";
import {
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Paper,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import ExportIcon from "@material-ui/icons/CloudDownloadOutlined";
import ChartsIcon from "@material-ui/icons/TimelineOutlined";

import { Measurement } from "./type";
import StyledTableCell from "../../common/components/StyledTableCell";
import StyledTableRow from "../../common/components/StyledTableRow";
import moment from "moment";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  analysisSelector,
  fetchMeasurements,
  clearAnalysisMeasurements,
  fetchCurrentAnalysis,
} from "./analysisSlice";
import { ANALYSIS_ENDPOINT } from "../../common/fetchUtils";
import useVisibility from "../../common/hooks/useVisibility";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    container: {
      paddingTop: "64px",
      alignItems: "start",
      paddingLeft: 0,
      paddingRight: 0,
    },
    icon: {
      paddingRight: theme.spacing(1),
    },
    downloadLink: {
      color: "inherit",
      textDecoration: "none",
    },
  })
);

type MeasurementsTableProps = {
  items: Measurement[] | null;
};

const MeasurementsTable: React.FC<MeasurementsTableProps> = (
  props: MeasurementsTableProps
) => {
  const classes = useStyles();
  const history = useHistory();

  const headers = [
    "#",
    "Sample Name",
    "Sample Group",
    "Lipid Name",
    "Standard Name",
    "Lipid Class",
    "Type",
    "Lipid Formula",
    "Polarity Mode",
    "Mass Info",
    "Concentration",
    "Peak Area",
    "Peak height",
    "Number",
  ];

  const row = (m: Measurement, index: number) => {
    const values = [
      index,
      m.sampleName,
      m.sampleGroup,
      m.lipidName,
      m.reference,
      m.lipidClass,
      m.type,
      m.lipidFormula,
      m.polarityMode,
      m.massInfo,
      m.concentration,
      m.peakArea,
      m.peakHeight,
      m.number,
    ];

    return (
      <StyledTableRow hover key={m.id}>
        {values.map((v, i) => (
          <StyledTableCell key={i}>
            <Typography variant="body1">{v}</Typography>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {headers.map((h, i) => (
              <StyledTableCell key={i}>
                <Typography variant="h6">{h}</Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items?.map((m: Measurement, index: number) =>
            row(m, index + 1)
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Analysis: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { analysisMeasurements, currentAnalysis } = useSelector(
    analysisSelector
  );
  const [isVisible, isVisibleRef] = useVisibility<HTMLDivElement>();

  const getAnalysisId = (): number => {
    const params: any = match.params;
    const analysisId = Number.parseInt(params?.id ?? "-1");
    if (analysisId === -1) {
      throw Error("Invalid parameter passed.");
    }
    return analysisId;
  };

  useEffect(() => {
    const request = {
      analysisId: getAnalysisId(),
      pageNumber: 1,
    };
    dispatch(fetchMeasurements(request));
    setCurrentPage(currentPage + 1);
    return () => {
      dispatch(clearAnalysisMeasurements(getAnalysisId()));
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const request = {
        analysisId: getAnalysisId(),
        pageNumber: currentPage,
      };
      dispatch(fetchMeasurements(request));
      setCurrentPage(currentPage + 1);
    }
  }, [isVisible]);

  useEffect(() => {
    dispatch(fetchCurrentAnalysis(getAnalysisId()));
  }, []);

  const analysisCsvUri = `/api/${ANALYSIS_ENDPOINT}${getAnalysisId()}/export/`;
  let baseUrl = "";
  if (process.env.NODE_ENV === "development") {
    baseUrl = "http://localhost:8050";
  }
  const analysisPlotsUri = `${baseUrl}/dash/?id=${getAnalysisId()}`;
  const filename = `${currentAnalysis?.name}.csv`;

  let measurements = null;
  const hasMore = analysisMeasurements[getAnalysisId()]?.hasMore ?? true;
  if (analysisMeasurements) {
    measurements = analysisMeasurements[getAnalysisId()]?.items ?? null;
  }

  const title = currentAnalysis
    ? `${currentAnalysis.name} - ${moment(currentAnalysis.created).format(
        "MMMM YYYY"
      )}`
    : "Analysis";
  return (
    <React.Fragment>
      <Header title={title}>
        <a
          target="blank"
          href={analysisPlotsUri}
          className={classes.downloadLink}
        >
          <IconButton color="inherit">
            <ChartsIcon className={classes.icon} />
            <Typography variant="button">PLOTS</Typography>
          </IconButton>
        </a>
        <a
          href={analysisCsvUri}
          download={filename}
          className={classes.downloadLink}
        >
          <IconButton color="inherit">
            <ExportIcon className={classes.icon} />
            <Typography variant="button">Export</Typography>
          </IconButton>
        </a>
      </Header>
      <Container maxWidth={false} className={classes.container}>
        <Grid container justify="center" alignItems="baseline">
          <MeasurementsTable items={measurements} />
          <div ref={isVisibleRef} style={{ margin: "16px" }}>
            {hasMore && <CircularProgress />}
          </div>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Analysis;
